<template>
  <div class="not-found-wrapper">
    <div class="not-found" />
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
<style scoped>
.not-found {
  width: 100%;
  height: 99%;
  background-image: url(./../assets/404.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.not-found-wrapper {
  background-blend-mode: lighten;
  background: #f4f9ff;
  width: 100%;
  height: 100%;
  display: inline-table;
}
</style>
